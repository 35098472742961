.block {
    position: relative;
    display: block;
    padding: theme('spacing.100');
    border-radius: theme('fields.borderRadius.default');
    border: 1px solid theme('colors.decorators.subtle');
    background-color: theme('fields.default.background');
    cursor: pointer;

    &:focus-visible {
        outline: none;
    }

    &:focus,
    &:hover {
        .indicator {
            border-color: theme('colors.actions.secondary');
        }
    }
}

.indicator {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: theme('fields.borderRadius.default');
    pointer-events: none;
}

.indicatorChecked {
    .indicator {
        border: 2px solid #1599E0;
        box-shadow: 0px 0px 0px 2px rgba(23, 167, 196, 0.10);
    }
}

.indicatorInvalid {
    .indicator {
        border-color: theme('colors.status.error.default');
        box-shadow: none;
    }
}

.indicatorDisabled {
    cursor: not-allowed;

    .indicator {
        border-color: theme('colors.decorators.subtle');
        box-shadow: none;
    }

    & > * {
        pointer-events: none;
    }
}
